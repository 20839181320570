import httpClient from "@/services/http.js";

const backendUrl =
  process.env.VUE_APP_WORKSHOP_BACKEND + "/workshop/next_assessment_step";

export const getQuestionsForCategoryFromDatabase = (answers, category) => {
  return httpClient
    .post(backendUrl, { truth: answers, category: category })
    .then((res) => res.data);
};

class QuestionService {
  fetchPossibleNextQuestions(truth, currentQuestionID) {
    return httpClient
      .post(backendUrl, {
        truth: truth,
        current_question: currentQuestionID,
      })
      .then((r) => r.data);
  }
}

export default new QuestionService();
