<template>
  <div class="flow-container">
    <div class="header">
      <div class="header-content">
        <div class="left" @click="previousStep()">
          <i v-show="hasPreviousQuestion" class="fa fa-chevron-left" />
        </div>
        <div class="middle">
          {{
            currentQuestion.categoryDisplayString || $t("lookAhead.category")
          }}
        </div>
        <div class="right" @click="showAbortDialog = true">
          <i class="fa fa-times" />
        </div>
      </div>
      <div class="progress-container">
        <span class="progress" :style="progress"></span>
      </div>
    </div>

    <div class="content">
      <div v-if="currentQuestion" class="step">
        <div class="widget">
          <div class="widget-label">
            {{ currentQuestion.label }}
          </div>
          <div class="widget-description">
            {{ currentQuestion.description }}
          </div>
        </div>

        <div class="widget-container">
          <div
            v-if="
              instructions.length > 0 && instructionsStep < instructions.length
            "
            class="instructions"
          >
            <div class="instruction-step">{{ instructionsStep + 1 }}</div>
            <div class="instruction">
              {{ instructions[instructionsStep].instruction }}
            </div>
            <div class="button primary" @click="instructionsStep++">
              {{ $t("general.continue") }}
            </div>
          </div>

          <template v-else>
            <component
              :is="currentQuestion.widgetType"
              :uid="currentQuestion.uid"
              :question="currentQuestion"
              @widget-event="answer => nextStep(answer)"
            />

            <answer-missing
              v-if="showMissingAnswerWidget"
              :questionUID="currentQuestion.uid"
              @skip-question="
                nextStep({
                  questionUid: currentQuestion.uid,
                  optionUid: 'MISSING_ANSWER'
                })
              "
            />
          </template>
        </div>
      </div>
    </div>

    <abort
      v-if="showAbortDialog"
      @close="showAbortDialog = false"
      @abort="abort()"
    />
  </div>
</template>

<script>
import persistenceService from "@/services/assessment-service.js";
import questionService from "@/services/question-service.js";

import Abort from "@/components/utility/Abort.vue";
import AnswerMissing from "@/components/utility/AnswerMissing.vue";
import MultiSelect from "@/components/widgets/MultiSelect.vue";
import NumberInput from "@/components/widgets/NumberInput.vue";
import PhotoUpload from "@/components/widgets/PhotoUpload.vue";
import RangeInput from "@/components/widgets/RangeInput.vue";
import ScrollInput from "@/components/widgets/ScrollInput.vue";
import ScrollRangeInput from "@/components/widgets/ScrollRangeInput.vue";
import SingleSelect from "@/components/widgets/SingleSelect.vue";
import TextInput from "@/components/widgets/TextInput.vue";

export default {
  name: "LookAheadFlow",
  components: {
    Abort,
    AnswerMissing,
    MultiSelect,
    NumberInput,
    PhotoUpload,
    RangeInput,
    ScrollInput,
    ScrollRangeInput,
    SingleSelect,
    TextInput
  },
  data() {
    return {
      truth: {},
      currentQuestion: {},
      possibleNextQuestions: [],

      instructionsStep: 0,
      showAbortDialog: false,

      showMissingAnswersForWidgetType: [
        "single-select",
        "multi-select",
        "scroll-input",
        "scroll-range-input",
        "range-input"
      ],

      questionHistory: [],

      prefetching: false
    };
  },
  async created() {
    if (!this.uid) {
      // starting new assessment
      this.$store.commit("initAssID");
    } else {
      // resuming old assessment
      this.resumeOldAssessment();
    }
    this.$loading(true);
    await this.fetchNextPossibleQuestions();
    this.$loading(false);
  },
  methods: {
    resumeOldAssessment() {
      this.truth = this.$store.getters.answers;
      this.questionHistory = Object.keys(this.truth);
    },
    async fetchNextPossibleQuestions() {
      this.prefetching = true;
      this.possibleNextQuestions = await questionService.fetchPossibleNextQuestions(
        this.truth,
        this.currentQuestion.uid || ""
      );
      this.prefetching = false;

      this.preloadInstructionPhotos(this.possibleNextQuestions);

      if (
        Object.keys(this.possibleNextQuestions).length == 1 &&
        this.possibleNextQuestions["next"]
      ) {
        this.currentQuestion = this.possibleNextQuestions["next"];
        this.fetchNextPossibleQuestions();
      }
    },
    async nextStep(answer) {
      this.truth[answer.questionUid] = answer.optionUid;
      this.syncToServer();

      await this.waitingForPrefetch();

      let lookupUID = answer.questionUid + "." + answer.optionUid;

      if (answer.type == "photo") {
        lookupUID = answer.questionUid + ".photo";
      }

      if (
        ["scroll-range-input", "range-input"].includes(
          this.currentQuestion.widgetType
        )
      ) {
        let decimals = this.currentQuestion.rangeWidget.decimals;
        if (decimals == undefined) decimals = 0;
        if (answer.optionUid !== "MISSING_ANSWER") {
          let withFixedDigits = answer.optionUid.toFixed(decimals);
          lookupUID = answer.questionUid + "." + withFixedDigits;
        }
      }

      if (answer.type == "text-input") {
        lookupUID = answer.questionUid + ".text";
      }
      if (answer.type == "number-input") {
        lookupUID = answer.questionUid + ".number";
      }
      if (answer.optionUid == "MISSING_ANSWER") {
        lookupUID = answer.questionUid + ".default";
      }

      this.questionHistory.push(this.currentQuestion.uid);

      if (!this.possibleNextQuestions[lookupUID]) {
        this.allQuestionsAnswered();
      } else {
        this.currentQuestion = this.possibleNextQuestions[lookupUID];
        this.fetchNextPossibleQuestions();
      }
    },
    syncToServer() {
      persistenceService.syncToServer(
        this.uid,
        this.truth,
        {},
        persistenceService.STATE_IN_PROGRESS
      );
    },
    previousStep() {
      const last = this.questionHistory.pop();
      delete this.truth[last];
      this.currentQuestion = {};
      this.fetchNextPossibleQuestions();
      this.syncToServer();
    },
    async waitingForPrefetch() {
      if (this.prefetching) {
        this.$loading(true);
      }
      while (this.prefetching) {
        await new Promise(r => setTimeout(r, 100));
      }
      this.$loading(false);
    },
    preloadInstructionPhotos(widgets) {
      widgets = Object.values(widgets);
      widgets
        .filter(w => w.widgetType == "photo-upload")
        .forEach(w => {
          const img = new Image();
          img.src = w.exampleImageURL;
        });
    },
    allQuestionsAnswered() {
      this.$store.commit("setTruth", this.truth);
      this.$router.push({ path: "/additional-information" });
    },
    abort() {
      this.$store.commit("resetState");
      this.$router.push("/");
    }
  },
  computed: {
    instructions() {
      if (this.currentQuestion && this.currentQuestion.instructions) {
        return this.currentQuestion.instructions;
      }
      return [];
    },
    hasPreviousQuestion() {
      return this.questionHistory.length > 0;
    },
    progress() {
      if (!this.currentQuestion || !this.currentQuestion.progress) {
        return "";
      }
      return "width: " + this.currentQuestion.progress * 100 + "%;";
    },
    showMissingAnswerWidget() {
      if (!this.currentQuestion) {
        return false;
      }
      return this.showMissingAnswersForWidgetType.includes(
        this.currentQuestion.widgetType
      );
    },
    uid() {
      return this.$store.getters.assID;
    }
  },
  watch: {
    currentQuestion() {
      document.scrollingElement.scrollTop = 0;
      this.instructionsStep = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.flow-container {
  max-width: 100vw;
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $primary;
}
.header-content {
  @include shadow();
  display: flex;
  color: white;

  div {
    height: 60px;
    line-height: 60px;
    max-height: 60px;
  }

  .left {
    padding-left: 20px;
  }
  .right {
    padding-right: 20px;
  }

  .middle {
    flex: 1;
    font-size: 14px;
  }
}
.widget-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.progress-container {
  height: 5px;
  position: relative;
  .progress {
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: $lightText;
  }
}
.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
}

.content {
  padding-top: 65px;
}

.step {
  min-height: calc(100vh - 65px);
  box-sizing: border-box;
  padding: 20px;
  max-width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

.widget-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.instructions {
  padding: 0px 30px;
  height: 100%;

  .instruction-step {
    margin: 30px auto;
    width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 50%;
    background-color: white;
    color: $primary;
  }

  .instruction {
    font-size: 19px;
  }

  .button {
    margin-top: 50px;
  }
}

.widget {
  word-break: break-word;
}

.widget-description {
  font-size: 20px;
  color: $lightText;
}
</style>
